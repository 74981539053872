/******************************************************************************************
PIXEL TO REM CONVERSION TABLE - Root Size: 16px
-------------------------------------------------------------------------------------------
PX   REM     |  PX   REM     |  PX   REM     |  PX   REM     |  PX   REM     |  PX   REM
-------------------------------------------------------------------------------------------
10 = 0.625   |  20 = 1.25    |  30 = 1.875   |  40 = 2.5     |  50 = 3.125   |  60 = 3.75
11 = 0.6875  |  21 = 1.3125  |  31 = 1.9375  |  41 = 2.5625  |  51 = 3.1875  |  61 = 3.8125
12 = 0.75    |  22 = 1.375   |  32 = 2       |  42 = 2.625   |  52 = 3.25    |  62 = 3.875
13 = 0.8125  |  23 = 1.4375  |  33 = 2.0625  |  43 = 2.6875  |  53 = 3.3125  |  63 = 3.9375
14 = 0.875   |  24 = 1.5     |  34 = 2.125   |  44 = 2.75    |  54 = 3.375   |  64 = 4
15 = 0.9375  |  25 = 1.5625  |  35 = 2.1875  |  45 = 2.8125  |  55 = 3.4375
16 = 1       |  26 = 1.625   |  36 = 2.25    |  46 = 2.875   |  56 = 3.5
17 = 1.0625  |  27 = 1.6875  |  37 = 2.3125  |  47 = 2.9375  |  57 = 3.5625
18 = 1.125   |  28 = 1.75    |  38 = 2.375   |  48 = 3       |  58 = 3.625
19 = 1.1875  |  29 = 1.8125  |  39 = 2.4375  |  49 = 3.0625  |  59 = 3.6875
-------------------------------------------------------------------------------------------
******************************************************************************************/

// Colors
$red:					#e30613;
$blue:					#0069b4;
$blue-hover:			#0078CF;
$green:					#a6c545;
$dark-green:			#2d936c;

$white:    #fff;
$gray-100: #f2f2f2;
$gray-200: #e9e9e9;
$gray-300: #dedede;
$gray-400: #cecece;
$gray-500: #adadad;
$gray-600: #666;
$gray-700: #494949;
$gray-800: #343434;
$gray-900: #212121;
$black:    #000;

$colors: (
				"blue":       $blue,
				"red":        $red,
				"green":      $green,
				"dark-green": $dark-green,
				"white":      $white,
				"gray":       $gray-600,
				"gray-light": $gray-100
);

$primary:				$blue;
$secondary:				$red;
$body-color:			$gray-600;
$dark:					$gray-600;
$light:					$gray-100;


// Options
$border-radius:					0;
$enable-negative-margins: 		true;
$variable-prefix:	            bs-;
$enable-caret:					false;
$enable-shadows:				true;
$enable-reduced-motion:			false;
$enable-deprecation-messages:	false;

// Fonts
$font-family-sans-serif:		PTSans, sans-serif;
$font-family-base:				$font-family-sans-serif;
$font-weight-lighter:			400;
$font-weight-light:				400;
$font-weight-normal:			400;
$font-weight-bold:				700;
$font-weight-bolder:			700;
$font-weight-base:				$font-weight-normal;
$font-size-root:				16px;
$font-size-base:              	1rem;
$line-height-base:            	1.4;

$h1-font-size:                	2.8125rem;
$h2-font-size:                	2.375rem;
$h3-font-size:                	2rem;
$h4-font-size:                	1.625rem;
$h5-font-size:                	1.25rem;
$h6-font-size:                	1.125rem;
$headings-line-height:			1.1	;
$headings-font-weight:			$font-weight-normal;
$headings-margin-bottom:      	.5em;
$headings-color:				$black;

// Navbar
$navbar-nav-link-padding-x:					.75rem;
$navbar-nav-link-padding-y:					1rem;
$navbar-light-color:						$black;
$navbar-light-hover-color:					$primary;
$navbar-light-active-color:					$primary;
$navbar-padding-y:							1rem;
$navbar-brand-padding-y:					0;
$nav-link-height:                   		$font-size-base * $line-height-base + $navbar-nav-link-padding-y * 2;

// Dropdown
$dropdown-spacer:							0;
$dropdown-min-width:						13rem;
$dropdown-border-width:						0;
$dropdown-box-shadow: 						0 1.25rem 1.25rem rgba($black, 0.16);
$dropdown-padding-y:                		1rem;
$dropdown-item-padding-x:					1.875rem;
$dropdown-item-padding-y:					1rem;
$dropdown-link-color:               		$black;
$dropdown-link-hover-color:         		$white;
$dropdown-link-hover-bg:					$primary;
$dropdown-link-active-color:				$white;
$dropdown-link-active-bg:					$primary;

// Buttons
$btn-padding-y:               				.75rem;
$btn-padding-x:				               	2.1875rem;
$btn-font-size:								1.5rem;
$btn-padding-y-sm:            				.5rem;
$btn-padding-x-sm:            				1.5rem;
$btn-font-size-sm:            				1.125rem;
$btn-border-width:							0;
$btn-font-weight:							$font-weight-bold;
$btn-box-shadow:							none;
$btn-focus-box-shadow:						none;
$btn-active-box-shadow:						none;
$btn-border-radius:           				0;
$btn-border-radius-sm:        				0;
$btn-border-radius-lg:        				0;

// Forms
$custom-control-indicator-size:           	1rem;
$custom-control-indicator-border-color:   	$black;
$input-btn-focus-width:						0;
$input-btn-focus-box-shadow:				unset;
$form-label-font-weight:					$font-weight-bold;
$form-label-color:                      	$black;
$input-border-radius:						2px;
$input-padding-x:							10px;
$input-padding-y:							6px;
$input-color:								#333;
$input-bg:									$white;
$input-border-color:						#ccc;
$input-font-size:							1rem;
$form-select-border-radius:					$input-border-radius;
$input-focus-border-color:					$black;

// Cards
$card-border-width:							0;
$card-border-radius:						0;
$card-spacer-y:								2rem;
$card-spacer-x:								2rem;