$btn-background: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='90' height='39' viewBox='0 0 90 39'><path d='M144.454,349.965l-87.1,2.012-1.652-36.713,90-2.287Z' transform='translate(-55.706 -312.977)' fill='#{$blue}'/></svg>"));
$btn-hover-background: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='90' height='39' viewBox='0 0 90 39'><path d='M144.454,349.965l-87.1,2.012-1.652-36.713,90-2.287Z' transform='translate(-55.706 -312.977)' fill='#{$blue-hover}'/></svg>"));
.btn {
    font-size: 1.25rem;
    &.btn-sm {
        font-size: 1rem;
    }
    @include media-breakpoint-up(lg) {
        font-size: $btn-font-size;
        &.btn-sm {
            font-size: $btn-font-size-sm;
        }
    }
}

.btn-primary {
    clip-path: polygon(0% 3px, 100% 0, 98% 97%, 3px 100%);
    @include button-variant($primary,
    $primary,
    $white,
    $blue-hover,
    $blue-hover,
    $white,
    $blue-hover,
    $blue-hover,
    $white,
    $blue-hover,
    $blue-hover,
    $white);
    &:active:focus {
        outline: none;
        box-shadow: $btn-active-box-shadow;
    }
    &:focus {
        outline: none;
        box-shadow: none;
    }
}

.btn-secondary {
    clip-path: polygon(0% 3px, 100% 0, 98% 97%, 3px 100%);
    @include button-variant($primary,
    $primary,
    $white,
    $blue-hover,
    $blue-hover,
    $white,
    $blue-hover,
    $blue-hover,
    $white,
    $blue-hover,
    $blue-hover,
    $white);
    position: relative;
    &:before {
        --btn-border-width: 2px;
        content: attr(data-title);
        position: absolute;
        top: var(--btn-border-width);
        left: var(--btn-border-width);
        right: var(--btn-border-width);
        bottom: var(--btn-border-width);
        clip-path: polygon(0% 3px, 100% 0, 98% 97%, 3px 100%);
        background-color: $white;
        color: $primary;
        padding: calc(#{$btn-padding-y} - var(--btn-border-width)) calc(#{$btn-padding-x} - var(--btn-border-width));
        line-height: $btn-line-height;
        transition: $btn-transition;
    }
    &.btn-sm {
        &:before {
            padding: calc(#{$btn-padding-y-sm} - var(--btn-border-width)) calc(#{$btn-padding-x-sm} - var(--btn-border-width));
        }
    }
    &:hover {
        &::before {
            background-color: $primary;
            color: $white;
        }
    }
    &:active:focus {
        outline: none;
        box-shadow: $btn-active-box-shadow;
    }
    &:focus {
        outline: none;
        box-shadow: none;
    }
}

.btn-filter {
    position: absolute;
    top: 5px;
    right: 10px;
    height: 40px;
    width: 40px;
    padding: 0;
    &:hover svg {
        color: var(--bs-blue);
    }
    svg {
        position: absolute;
        top: 11px;
        left: 10px;
        color: var(--bs-gray);
        height: 20px;
        width: 20px;
    }
}