/* pt-sans-regular - latin */

@font-face {
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/ptsans/pt-sans-v17-latin-regular.eot');
    /* IE9 Compat Modes */
    src: local(''), url('../fonts/ptsans/pt-sans-v17-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/ptsans/pt-sans-v17-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/ptsans/pt-sans-v17-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('../fonts/ptsans/pt-sans-v17-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/ptsans/pt-sans-v17-latin-regular.svg#PTSans') format('svg');
    /* Legacy iOS */
}


/* pt-sans-italic - latin */

@font-face {
    font-family: 'PT Sans';
    font-style: italic;
    font-weight: 400;
    src: url('../fonts/ptsans/pt-sans-v17-latin-italic.eot');
    /* IE9 Compat Modes */
    src: local(''), url('../fonts/ptsans/pt-sans-v17-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/ptsans/pt-sans-v17-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/ptsans/pt-sans-v17-latin-italic.woff') format('woff'), /* Modern Browsers */
    url('../fonts/ptsans/pt-sans-v17-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/ptsans/pt-sans-v17-latin-italic.svg#PTSans') format('svg');
    /* Legacy iOS */
}


/* pt-sans-700 - latin */

@font-face {
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/ptsans/pt-sans-v17-latin-700.eot');
    /* IE9 Compat Modes */
    src: local(''), url('../fonts/ptsans/pt-sans-v17-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/ptsans/pt-sans-v17-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/ptsans/pt-sans-v17-latin-700.woff') format('woff'), /* Modern Browsers */
    url('../fonts/ptsans/pt-sans-v17-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/ptsans/pt-sans-v17-latin-700.svg#PTSans') format('svg');
    /* Legacy iOS */
}


/* pt-sans-700italic - latin */

@font-face {
    font-family: 'PT Sans';
    font-style: italic;
    font-weight: 700;
    src: url('../fonts/ptsans/pt-sans-v17-latin-700italic.eot');
    /* IE9 Compat Modes */
    src: local(''), url('../fonts/ptsans/pt-sans-v17-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/ptsans/pt-sans-v17-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/ptsans/pt-sans-v17-latin-700italic.woff') format('woff'), /* Modern Browsers */
    url('../fonts/ptsans/pt-sans-v17-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/ptsans/pt-sans-v17-latin-700italic.svg#PTSans') format('svg');
    /* Legacy iOS */
}