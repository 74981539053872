.tankstellenfinder {
    position: absolute;
    width: 100%;
    height: 100%;
    .map {
        position: relative;
        height: 100%;
        width: 100%;
        .outer-marker,
        .cluster-marker {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            background-color: $primary;
            color: #fff;
            cursor: pointer;
            box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.6);
            &:hover {
                background-color: $secondary;
            }
        }
        .outer-marker {
            width: 1.5rem;
            height: 1.5rem;
            &.outer-marker-red {
                background-color: $secondary;
            }
        }
        .inner-marker {
            background: #fff;
            width: 0.5rem;
            height: 0.5rem;
            border-radius: 50%;
        }
        .cluster-marker {
            border: 1px solid white;
            width: 1.8rem;
            height: 1.8rem;
            font-weight: bold;
            font-size: 1rem;
        }
    }
    .sidebar,
    .sidebar-header {
        width: calc(100% - 2rem);
        @include media-breakpoint-up(sm) {
            width: 400px;
        }
    }
    .sidebar {
        position: absolute;
        top: 10px;
        left: 10px;
        overflow-y: scroll;
        border-radius: 6px;
        background-color: rgba(255, 255, 255, 0.85);
        box-shadow: 4px 0 10px rgba(0, 0, 0, 0.4);
        max-height: 50%;
        @include media-breakpoint-up(lg) {
            height: fit-content;
            max-height: calc(100% - 33px);
        }
        /*
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        background-color: rgba(255, 255, 255, 0.45);
    }
    */
        .sidebar-header {
            position: fixed;
            border-bottom: 1px solid $gray-300;
            .search {
                .input-group {
                    position: relative;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: stretch;
                    width: 100%;
                    .form-control,
                    .input-group-text {
                        font-size: 1.5rem;
                        padding: .7rem 1.2rem;
                    }
                    .form-control {
                        position: relative;
                        flex: 1 1 auto;
                        width: 1%;
                        min-width: 0;
                        border: none;
                        border-radius: 0;
                    }
                    .input-group-text {
                        background-color: $primary;
                        color: white;
                        border: none;
                        border-radius: 0;
                        .svg-inline--fa {
                            color: white;
                        }
                    }
                    input:focus-visible {
                        outline: none;
                    }
                }
            }
        }
        .list-group {
            margin-top: 55px;
            .no-findings {
                color: var(--bs-danger);
                padding: 0.7rem 1.2rem;
                p {
                    margin-bottom: 0;
                }
            }
            .list-group-item {
                padding: 0;
                border: none;
                border-radius: 0;
                border-top: 1px solid $gray-300;
                background-color: transparent;
                &:first-child {
                    border-top: none;
                }
                &>div {
                    padding: 1rem 1.2rem;
                    cursor: pointer;
                    &.active {
                        background-color: $gray-300;
                    }
                    &>*:last-child {
                        margin-bottom: 0;
                    }
                    .name,
                    .address {
                        margin-bottom: .5rem;
                    }
                }
            }
        }
    }
}

.openhours {
    font-weight: $font-weight-bold;
    &.status-open svg {
        color: var(--bs-green);
    }
}

.station-status {
    display: inline-block;
    font-weight: $font-weight-normal;
    &.status-open {
        color: $green;
    }
    &.status-closed {
        color: $secondary;
    }
}

.svg-inline--fa {
    color: $secondary;
    margin-right: .5rem;
    width: 1rem;
    height: auto;
}

.station-overlay {
    display: none;
}

@media only screen and (max-width: 410px) {
    .col-xs-12 {
        flex: 0 0 auto;
        width: 100%;
    }
}