@font-face {
    font-family: Montserrat;
    src:    local('Montserrat'),
            url('../fonts/montserrat/montserrat-regular-webfont.woff2') format('woff2'),
            url('../fonts/montserrat/montserrat-regular-webfont.woff') format('woff');
    font-style:   normal;
    font-weight:  400;
    font-display: swap;
}

@font-face {
    font-family: Montserrat;
    src:    local('Montserrat ExtraBold'),
            url('../fonts/montserrat/montserrat-extrabold-webfont.woff2') format('woff2'),
            url('../fonts/montserrat/montserrat-extrabold-webfont.woff') format('woff');
    font-style:   normal;
    font-weight:  700;
    font-display: swap;
}