html,
body,
#root {
    height: 100%;
    background-color: $gray-200;
}

.content {
    padding-top: 4rem;
}

.station_detail {
    filter: drop-shadow(0 0 1rem rgba($black, .08));
    .card-header {
        padding-top: 2rem;
        padding-bottom: 0;
        @include media-breakpoint-down(lg) {
            padding-left: 1rem;
            padding-right: 1rem;
        }
        h1 {
            margin-bottom: .25rem;
        }
        address {
            font-size: 1.25rem;
            color: $primary;
            margin-bottom: 0;
        }
    }
    .card-body {
        .station_images {
            margin-bottom: 2rem;
        }
        .station-link {
            display: block;
        }
        .attribute_hl {
            font-size: $h4-font-size;
        }
        .fuels,
        .payments,
        .features {
            margin: 0;
            padding: 0;
            list-style: none;
            li {
                svg {
                    margin-right: .5rem;
                }
            }
        }
        .fuels {
            .svg-inline--fa {
                color: $green;
            }
        }
        .payments {
            &>li {
                margin: 5px 0;
                .payment-image {
                    display: inline-block;
                    width: 22px;
                    height: auto;
                    margin-right: 10px;
                }
            }
            .svg-inline--fa {
                color: $gray-600;
            }
        }
        .features {
            display: flex;
            flex-wrap: wrap;
            li {
                margin-right: 1.5rem;
            }
        }
    }
    .backlink {
        position: absolute;
        top: -0.75rem;
        right: 1rem;
        .btn-primary {
            background-color: $secondary;
            .svg-inline--fa {
                color: white;
            }
        }
    }
    dd {
        margin-bottom: 0;
    }
}