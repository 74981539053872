.shadow-hover {
  transition: filter .15s ease-in-out;
  filter: drop-shadow(0 0 1rem rgba($black, .08));

  @include media-breakpoint-up(md) {
    filter: none;
    &:hover, &:focus {
      filter: drop-shadow(0 0 1rem rgba($black, .08));
    }
  }
}

.card-clip-border {
  clip-path: polygon(0% 3%, 100% 0, 98% 97%, 3% 100%);
  padding: 3px 3px;
  background-color: $gray-100;
  transition: background-color .15s ease-in-out;

  @include media-breakpoint-up(md) {
    &:hover, &:focus {
      background-color: $red;
    }
  }

  .card-header {
    padding: $card-spacer-y * .5 1rem $card-spacer-y * .3;
    @include media-breakpoint-up(lg) {
      padding: $card-spacer-y * .5 $card-spacer-x $card-spacer-y * .3;
    }
  }
}

.card-body {
  padding: 1rem 1rem;
  @include media-breakpoint-up(lg) {
    padding: $card-spacer-y $card-spacer-y;
  }
  p:last-child {
    margin-bottom: 0;
  }
}

.card-img, .card-img-top, .card-img-bottom {
  height: auto;
}

.card-footer {
  padding: 0 1rem 1rem 1rem;
  @include media-breakpoint-up(lg) {
    padding: 0 $card-spacer-x $card-spacer-y $card-spacer-x;
  }
  background-color: transparent;
}

.card-title {
  @include font-size(1.375rem);
  font-weight: $font-weight-bold;
}

.card-title-link {
  font-size: 1rem;
  line-height: 1.2;
  @include media-breakpoint-up(sm) {
    font-size: 1.125rem;
  }
  @include media-breakpoint-up(lg) {
    font-size: 1.25rem;
  }
  a {
    color: $black;
    text-decoration: none;
    strong {
      color: $primary;
      @include media-breakpoint-up(md) {
        color: $black;
      }
    }
  }
}

.card {

  &.clip-path {
    clip-path: polygon(0% 3%, 100% 0, 98% 97%, 3% 100%);
  }

  @include media-breakpoint-up(md) {
    &.img-hover {
      overflow: hidden;
      .card-img {
        transform: scale(1);
        transition: transform .15s ease-in-out;
      }
      .card-img-overlay {
        background: transparent;
        transition: background .15s ease-in-out;
      }
      .big-title {
        position: relative;
        top: 0;
        transition: top .15s ease-in-out;
      }
      &:hover, &:focus {
        .card-img {
          transform: scale(1.05);
        }
        .card-img-overlay {
          background: radial-gradient(ellipse at center, rgba(0,0,0,0) 40%,rgba(255,255,255,.15) 100%);
        }
        .big-title {
          top: -5px;
        }
      }
    }
    &:hover, &:focus {
      .card-title-link {
        a {
          strong {
            color: $primary;
          }

          text-decoration: none;
        }
      }
    }
  }
}