.offcanvas {
    width: 100%;
    box-shadow: 4px 0 10px rgb(0 0 0 / 40%);
    &.show {
        @media only screen and (min-width: 820px) {
            height: fit-content;
            max-height: calc(100% - 33px);
        }
    }
    @media only screen and (min-width: 820px) {
        width: 400px;
        margin-right: 10px;
        margin-top: 10px;
        margin-bottom: 22px;
    }
    .offcanvas-header {
        padding: 1rem 1.2rem;
        background-color: rgba(250, 250, 250, 1);
        position: relative;
        .btn-close {
            border: none;
            background-color: transparent;
            padding: 0;
            margin: 0;
            width: 25px;
            height: 25px;
            position: absolute;
            top: calc(50% - 12px);
            right: 1rem;
            svg {
                height: 100%;
                width: auto;
                margin-right: 0;
                color: var(--bs-gray);
            }
            &:hover svg {
                color: var(--bs-blue);
            }
        }
    }
    .offcanvas-body {
        padding: 0;
        .filter-wrapper {
            padding: 1rem 1.2rem;
            border-top: 1px solid #dedede;
        }
        h3 {
            color: var(--bs-blue);
            font-weight: $font-weight-bold;
            margin-bottom: 1rem;
        }
        label {
            position: relative;
            cursor: pointer;
            margin-bottom: 10px;
            word-wrap: break-word;
            -webkit-hyphens: auto;
            -moz-hyphens: auto;
            -ms-hyphens: auto;
            hyphens: auto;
            &:last-child {
                margin-bottom: 0;
            }
            &.geöffnet {
                font-weight: $font-weight-bold;
            }
            svg {
                color: var(--bs-gray);
                margin-right: 2px;
            }
            &.filter-selected {
                color: var(--bs-blue);
                svg {
                    color: var(--bs-blue);
                }
                .checkmark {
                    background-color: var(--bs-blue);
                    &::after {
                        display: block;
                    }
                }
            }
        }
        input {
            cursor: pointer;
            height: 0;
            width: 0;
            margin-right: 24px;
            -webkit-transform: scale(0, 0);
        }
        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 17px;
            width: 17px;
            background-color: #ebebeb;
        }
        .checkmark::after {
            content: "";
            position: absolute;
            display: none;
            left: 7px;
            top: 3px;
            width: 5px;
            height: 10px;
            border: solid #fff;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
}